* {
    box-sizing: border-box;
}

.order-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-form-title {
    align-self: center;
    font-size: 25px;
}

.order-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
}

.order-form-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #5D201F;
    padding: 30px;
}
.order-form-row{
    display: flex;
    flex-direction: row;
}

.order-input {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.order-input > input {
    border: none;
    border-radius: 10px;
    background-color: #FBBB92;
    height: 30px;
    padding: 0 15px;
    font-size: 15px;
    width: 200px;
    margin-top: 5px;
}

.order-input > textarea {
    border: none;
    border-radius: 10px;
    background-color: #FBBB92;
    margin-top: 5px;
    padding: 15px 15px;
    font-size: 15px;
}

.order-input-left {
    margin-left: auto;
    padding-left: 100px;
}

.order-dicom-container {
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}

.order-dicom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9D9D9;
    width: 250px;
    height: 250px;
}

.order-form-buttons {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.order-form-btn {
    align-self: center;
    margin-top: 40px;
    padding: 10px 20px;
    font-size: 20px;
    color: white;
    background-color: #C74B30;
    border-radius: 10px;
    border: none;
}
