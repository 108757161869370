.footer {
    display: flex;
    justify-content: center;
}

.footer-logout{
    margin: 10px;
    background-color: #5D201F;
    color: white;
    border: none;
    padding: 5px;
    margin-left: 50px;
    border-radius: 5px;
}