.comm-alert-dialog {
    padding: 15px;
} 

.comm-dialog-title{
    color: #FBBB92;
}

.comm-dialog-body {
    padding: 15px 10px;
}

.comm-dialog-confitm-btn {
    margin-right: auto;
    font-size: 12px;
    color: white;
    background-color: #C74B30;
    border-radius: 10px;
    border: none;
}

.comm-dialog-close-btn {
    font-size: 12px;
    color: white;
    border-radius: 10px;
    border: none;
}

.commn-dialog-btn {
    margin-top: auto;
    align-self: center;
    font-size: 12px;
    color: white;
    background-color: #C74B30;
    border-radius: 10px;
    border: none;
}