.commn-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.commn-title {
    font-size: 25px;
}

.commn-parent {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.commn-dicom-view {
    background-color: #D9D9D9;
    width: 45%;
    height: 70vh;
}


.commn-dicom-iframe{
    width: 100%;
    height: 100%;
    border: none;
}

.commn-msg-control {
    width: 45%;
    margin: 10px;
}


.commn-messenger {
    display: flex;
    flex-direction: column;
    background-color: #D9D9D9;
    width: 100%;
    padding: 10px;
    height: 65%;
}

.commn-msg-sendbird{
    height: 80%;
    width: 100vh;
}

.commn-summary-control {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30%;
    margin-top: 20px;
}

.commn-summary {
    background-color: #D9D9D9;
    width: 55%;
    padding: 5px 10px;
}

.commn-summary-label {
    display: inline-block;
    width: 100px;
    text-align: right;
    font-size: 0.75rem;
    margin: 5px 10px;
}

.commn-controls {
    display: flex;
    flex-direction: column;
    background-color: #D9D9D9;
    margin-left: auto;
    width: 35%;
    padding: 10px 20px;
}

.commn-controls-btn {
    margin-top: auto;
    padding: 7px;
    width: 100px;
    align-self: center;
    font-size: 15px;
    color: white;
    background-color: #C74B30;
    border-radius: 10px;
    border: none;
}

