.nav{
    width: 100%;
    height: 15%;    
    padding: 10px 25px;
}

.nav-contents {
    width: 100%;
    color: #EA6519;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.nav-logo{
    width: 100px;
    height: 70px;
}

.nav-user-avatar{
    width: 40px;
    height: 45px;
    /* padding-right: 10px; */
    padding-top: 5px;
}

.nav-logo-container{
    width: 150px;
    display: flex;
    justify-content: flex-start;
}

.nav-avatar-container{
    width: 100px;
    display: flex;
    justify-content: center;
    position: relative;
}

.nav-divider{
    height: 8px;
    background-color: #FBBB92;
    border-width: 0;
    box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
}

.nav-avatar-pop{
    font-size: 0.5em;
    color: black;
    
    align-items: center;
    justify-items: center;
}

.nav-avatar-pop > ul {
    list-style: none;
    width: "100%";
    text-align: center !important;
    align-self: center;
    background-color: #FBBB92;
    border-radius: 5px;
    padding: 10px 15px;
}

.nav-avata-pop-btns {
    padding: 5px 0;
    background-color: #FBBB92;
    border: none;
    display: flex;
    flex-direction: row;
}

