.table-container {
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.table-title {
    align-self: center;
    font-size: 25px;
}

.rdt_TableHeader{
    align-self: center;
}

.table-btn {
    margin-top: 30px;
    align-self: center;
    padding: 10px 20px;
    font-size: 20px;
    color: white;
    background-color: #C74B30;
    border-radius: 10px;
    border: none;
}

/* .order-side-pop {
    display: flex;
    flex-direction: row;
}

.order-side-pop > ul {
    list-style: none;
    /* width: "100%";
    background-color: #FBBB92;
    border-radius: 5px;
    padding: 2px 5px;
    margin-left: 30px;
    margin-top: 2px;
}

.order-actions-side {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin: 2px 5px;
} */

.order-actions {
    margin-right: 10px;
    width: 30px;
}
