.login {
    width: 100%;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-logo {
    width: 500px;
}

.login-form {
    background-color: #FBBB92;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    border-radius: 15px;
    color: #5D201F;
}

.login-title {
    font-weight: 400;
    font-size: 25px;
    line-height: 40px;
    margin-top: 0;
}

.login-form input {
    border-width: 0;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: 15px;
    width: 150px;
    height: 25px;
    padding: 10px 10px;
}

.login-btn {
    margin-top: 40px;
    padding: 5px 15px;
    font-size: 18px;
    color: white;
    background-color: #5D201F;
    border-radius: 10px;
    border: none;
}

.login-pw-rest {
    font-size: 10px;
}

.login-error {
    text-align: end;
    font-size: 13px;
}

.login-denied {
    color: red;
    font-size: 15px;
    text-align: center;
}